
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

import Implementator from "@/components/1crm/sales/implementator/implementator.vue";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Implementator,
  },
  props: {
    widgetClasses: String,
  },
  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH_BANK);
    setCurrentPageBreadcrumbs("Implementator", ["Data"]);
  },

});
